<fieldset class="cm-o-form-section c-price-plan-selector" v-if="options">
  <p class="cm-o-form-section__title c-price-plan-selector__title">
    {{ headline }}
  </p>

  <existing-subscription-selector v-if="enableProlonging && showExistingSubscriptionSelector"
    :product-code="productCode" />

  <c-carousel :page-dots="true" :multi-slider-speed="500" :data="options" :index="selectedIndex" :navigation="true"
    :loop="false" :items-per-slide="4" :smooth-swiping="true" :rounded-navigation-buttons="true"
    :navigation-position-fixed="true" class="product-carousel" @closeTongue="closeTongue = true" @ready="carouselReady">
    <template slot="slide" slot-scope="{ data }">
      <subscription-card :value="data.code" :selected="selected.code" :title="data.displayName"
        :attr="dataIncluded(data.dataIncluded, data.dataUnit)" :card-background-color="data.cardBackgroundColor"
        :description="formattedDescription(data)"
        :original-price-text="data.price.originalPrice && `${data.price.originalPrice.value } ${data.price.originalPrice.label}`"
        :price-text="`${data.price.productBasePrice.value} ${data.price.productBasePrice.label}`"
        :action-selected-text="data.chooseLabel" :action-text="data.chosenLabel" :tongue-trigger-text="vasHeadline"
        :vas="data.valueAddedServices" :close-tongue="closeTongue" :ribbon-text="data.recommendedText"
        :badge-text="data.badgeText.item2" @change="onPaymentPlanSelected(data)"
        @collapsed="closeTongue = false"></subscription-card>
    </template>
  </c-carousel>
</fieldset>