<div class="cm-c-card-product" v-if="!isNewProductCard">
  <component
    :is="componentType"
    v-bind="componentProps"
    class="cm-c-card-product__link cm-c-card-productimage__link"
    @click.prevent="pushEEC(product, variantFragment)"
  >
    <figure class="cm-c-card-product__photo">
      <div
        v-if="selectedVariant && selectedVariant.badge && selectedVariant.badgeText"
        class="cm-c-card-product__splash cm-c-splash"
        :class="selectedVariant.badgeText.item1"
      >
        <span class="cm-c-splash__wrap">
          {{ selectedVariant.badgeText.item2 }}
        </span>
      </div>

      <product-category-gallery
        :images="variantImages"
        :productName="product.productName"
      />
    </figure>
  </component>
  <nav class="cm-c-product-colors">
    <div
      v-for="(color, index) in product.availableColors"
      :key="index"
      :style="{ background: color.hexCode }"
      @click="selectColor(color)"
      class="cm-c-product-colors-color"
      :class="{
        '-border': checkColor(color.hexCode),
        '-selected':
          color &&
          selectedColor &&
          color.colorName === selectedColor.colorName
      }"
    />
  </nav>

  <div class="cm-c-card-storage" v-if="storageSelectorEnabled">
    <div
      v-for="variant in selectedColor.variants"
      class="cm-c-card-storage__group"
      :key="variant.code"
    >
      <input
        :id="`storage-${variant.code}`"
        type="radio"
        :name="`storage-${variant.code}`"
        :value="variant.minimumStorageCapacity"
        :checked="
          variant.minimumStorageCapacity ==
            selectedVariant.minimumStorageCapacity
        "
        @click="storageUpdate()"
        class="sr-only"
      />

      <label :for="`storage-${variant.code}`">
        {{ variant.minimumStorageCapacity }}
      </label>
    </div>
  </div>

  <component
    :is="componentType"
    v-bind="componentProps"
    class="cm-c-card-product__link cm-c-card-product-info"
    @click.prevent="pushEEC(product, variantFragment)"
  >
    <h3 class="cm-c-card-product__title">{{ product.productName }}</h3>
    <p class="cm-c-card-product__price">
      <span v-if="storageSelectorEnabled">
        {{ selectedVariant.price.formattedValueCurrency }}
      </span>

      <template v-else>
        <span>{{ price }}</span>

        <span>
          <span>{{ priceText }}</span>

          <span>{{ product.defaultInstallmentPeriod }}</span>
        </span>
      </template>
    </p>

    <div v-if="!minimumPriceDisabled" class="cm-c-card-product__desc">
      <span v-if="subscriptionText">{{ subscriptionText }}</span>

      <p v-if="!minimumPriceDisabled">
        {{ product.minimumPrice }}
      </p>
    </div>
  </component>

  <div v-if="!compareDisabled" class="cm-c-card-product__action">
    <c-checkbox
      :id="product.productId"
      :value="product.productId"
      @click="setCompareItem(product)"
      :name="product.productId"
      class="cm-c-card-product__action-checkbox"
      :checked="isCompareItem(product.productId)"
      :disabled="compareCount >= compareMax && !isCompareItem(product.productId)"
      @event-id="setCompareItem(product)"
    >
      {{ compareLabel }}
    </c-checkbox>

    <div
      class="cm-c-product-tooltip__container with-tooltip"
      @mouseover="fetchSpecifications"
    >
      <product-info :data="specifications" class="productcard" />

      <button
        disabled
        class="cm-icon cm-icon-info cm-link--minimum-price cm-link--product-info js-minimum-price-tooltip-activator"
      >
        <c-icon
          class="cm-icon cm-icon-info search-help--icon"
          symbol-id="contact-information"
        />
      </button>
    </div>
  </div>

  <div v-if="!link">
    <button class="c-btn c-btn--xs" @click.prevent="onSelectVariant">
      {{ selectLabel }}
    </button>
  </div>
</div>

<component class="cm-c-product-card-simple"  v-else
  :class="product.priceDisclaimerText ? 'cm-c-product-card-simple--extra-margin' : ''" :is="componentType"
  v-bind="componentProps" @click.prevent="onCardClick">
  <div class="cm-c-product-card-simple__inner" :class="{ 'is-active' : isCompareItem(product.productId) }">
    <div class="cm-c-product-card-simple__ribbon" v-if="selectedVariant && (selectedVariant.ribbonText || product.ribbonText)">
      <span class="c-badge c-badge--primary c-badge--top-left">
        {{ ribbonText }}
      </span>
    </div>

    <div class="cm-c-product-card-simple__left">
      <figure class="cm-c-product-card-simple__photo">
        <!-- TODO DKT-1381: add alt text from cms -->
        <img v-if="!!defaultImage" ref="image" :key="defaultImage" :src="defaultImage" alt="produkt" />
      </figure>

      <div class="cm-c-splash" :class="selectedVariant.badgeText.item1" v-if="
          selectedVariant &&
          selectedVariant.badge &&
          selectedVariant.badgeText &&
          selectedVariant.badgeText.item2
        ">
        <span class="cm-c-splash__wrap" v-html="selectedVariant.badgeText.item2"></span>
      </div>

      <div v-if="!compareDisabled" class="cm-c-product-card-simple__action">
        <label class="cm-c-checkbox cm-c-checkbox--center">
          <input :id="id" type="checkbox" :value="product.productId" :checked="isCompareItem(product.productId)"
            ref="compare" :disabled="compareCount >= compareMax && !isCompareItem(product.productId)"
            class="cm-c-checkbox__o-input" />

          <span class="cm-c-checkbox__icon">
            <c-icon class="cm-c-checkbox__compare" symbol-id="service-compare" />
            <c-icon class="cm-c-checkbox__comparing" symbol-id="service-comparing" />
          </span>

          <!-- DKT-19854: Replace Tilføjet with CMS property -->
          <span class="cm-c-checkbox__label cm-c-checkbox__label--center" :style="
                `
                --added-string: 'Tilføjet';
                --compare-string: '${compareLabel}';
              `
              "></span>
        </label>
      </div>
    </div>
    <div class="cm-c-product-card-simple__right">
      <div class="cm-c-product-card-simple__top">
        <p class="cm-c-product-card-simple__brand" v-trim>
          {{ product.productBrand }}
        </p>

        <p class="cm-c-product-card-simple__title" v-trim>
          {{ productName(product.productName, product.productBrand) }}
        </p>

        <nav class="cm-c-product-colors cm-c-product-colors--left" v-if="hasColorGroups">
          <!-- TODO DKT-1381: Replace "colorArray" with "product.colorArray" below, remove colorArray(){ .,..} from "computed" -->
          <div v-for="(color, index) in product.availableColors" :key="index" :style="{ background: color.hexCode }"
            class="cm-c-product-colors-color cm-c-product-colors-color--small" :class="{
                'cm-c-product-colors-color--border': checkColor(color.hexCode),
                'cm-c-product-colors-color--selected':
                  color &&
                  selectedColor &&
                  color.colorName === selectedColor.colorName
              }"></div>
        </nav>
      </div>
      <div class="cm-c-product-card-simple__bottom"
        :class="{ 'cm-c-product-card-simple__bottom--with-button': addToBasketButtonEnabled }">
        <p v-if="product.supplementaryPriceText && product.supplementaryPriceText !== ''"
          class="cm-c-product-card-simple__price-text" v-trim>
          {{ product.supplementaryPriceText }}
        </p>
        <p class="cm-c-product-card-simple__price">
          <template>
            <span>{{ price }}</span>

            <span>
              <span>{{ priceText }}</span>
            </span>
          </template>
        </p>
        <div class="cm-c-product-card-simple__desc">
          <p v-for="text in metaLines" :key="text" v-trim>
            {{ text }}
          </p>

          <c-button type="button" class="c-btn--xs" :class="animationClass" :disabled="addButtonDisabled"
            v-if="addToBasketButtonEnabled" @click="addToBasketButtonClickEvent ? addProductToBasket($event) : null">
            <span class="c-btn__text">
              <c-icon v-if="addToBasketButtonClickEvent && submitOptions.showSuccessIcon" class="c-btn__icon"
                symbol-id="service-checkmark" />

              <c-icon v-else-if="addToBasketButtonClickEvent" class="c-btn__icon c-btn__icon--error "
                symbol-id="menu-close" />
                
              <span v-trim>{{ addToBasketButtonText }}</span>
            </span>
          </c-button>
        </div>
      </div>
    </div>
  </div>

  <p v-if="product.priceDisclaimerText" class="cm-c-product-card-simple__vat-disclaimer">
    {{ product.priceDisclaimerText }}
  </p>
</component>