/* eslint-disable import/no-unresolved */
// TODO DKT-10786: how to properly resolve dynamic aliases with eslintimport Vue from 'vue';
import Vue from 'vue';
import VueResource from 'vue-resource';
import Vuex, { mapMutations, mapActions, Store } from 'vuex';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import PortalVue from 'portal-vue';
import Validation from 'webshop/plugins/validation.js';
import 'webshop/features/roamingprices-b2b';
import ClickOutside from 'webshop/plugins/click-outside.js';
import { protoType } from 'webshop/js/prototype.js';
import { safariPolyFill, iePolyFill } from 'webshop/js/browser_hacks.js';
import { a11yMenu } from 'webshop/js/a11ymenu.js';
import { Slider } from 'webshop/js/slider.js';
import { observeAnimations } from 'webshop/js/animations.js';
import initOneBlock from '../../../oneblock/assets/js/oneblock-init.mjs';
import { humany } from 'webshop/js/humany.js';
import { countDown } from 'webshop/js/countdown.js';
import { uspRibbon } from 'webshop/features/usp-ribbon';
import { banner } from 'webshop/features/banner';
import { tabs } from 'webshop/features/tabs';
import { epiFormsSteps } from 'webshop/features/epi-forms-steps';
import svg4everybody from 'svg4everybody';
import VueScrollTo from 'vue-scrollto';
import { MediaQueries } from 'vue-media-queries';
import Toast from 'webshop/plugins/toast.js';
import Tooltip from 'v-tooltip';

//fix for MS Edge with broken fetch implementation (version less than 18)
if (
  !window.fetch ||
  (/Edge\/\d./i.test(navigator.userAgent) &&
    parseInt(
      /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(navigator.userAgent)[2],
      10
    ) < 18)
) {
  window.fetch = undefined;
  require('whatwg-fetch');
}

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('./images/icons/', true, /\.svg$/));

import { providerMixin as themeProviderMixin } from 'olympus/mixins/variant-mixin.js';
import { default as checkoutProviderMixn } from 'webshop/providers/checkout-provider.js';
import { default as toastProviderMixin } from 'webshop/providers/toast-provider.js';

import VToggle from 'olympus/directives/v-toggle.js';
import { VModal } from 'olympus/directives/v-modal.js';
import VTrim from 'olympus/directives/v-trim.js';

// solution level
import GlobalContainer from 'olympus/plugins/global-container.js';
import {
  CVideo,
  CThemeBlock,
  CModal,
  CToastManager,
  CToast,
  CBox,
  CInformationBox
} from 'olympus/components';

// application-level
import ShoutingFont from 'webshop/components/generic/shouting-font.vue';
import ChannelHeader from 'webshop/components/generic/channel-header.vue';
import CookieDisclaimer from 'webshop/components/generic/cookie-disclaimer.vue';
import ModalDialog from 'webshop/components/generic/modal-dialog.vue';
import SessionTimeoutOverlay from 'webshop/components/generic/session-timeout-overlay/session-timeout-overlay.vue';
import CCompare from 'webshop/components/catalog/c-compare/c-compare.vue';
import OverlaySpinner from 'webshop/components/generic/overlay-spinner.vue';
import RedirectionPopup from 'webshop/components/generic/redirection-popup.vue';
import ProductCategoryOverview from 'webshop/components/catalog/product-category-overview.vue';
import TopupLandingPage from 'webshop/components/topup/topup-landing-page.vue';
import TopupResult from 'webshop/components/topup/topup-result.vue';
import ShopFooter from 'webshop/components/generic/shop-footer.vue';
import ProductPage from 'webshop/components/product/product-page.vue';
import SubscriptionPage from 'webshop/components/subscription/subscription-page.vue';
import OrderWrapper from 'webshop/components/orders/order-wrapper.vue';
import SubscriptionPageSubcard from 'webshop/components/subscription/subscription-page-subcard.vue';
import EpiContent from 'webshop/components/epi-content/epi-content.vue';
import QuickLinks from 'webshop/components/generic/quick-links.vue';
import CheckoutDeliveryAddress from 'webshop/components/checkout/checkout-delivery-address.vue';
import BroadbandOfferingsPage from '../shared/components/broadband-offerings-page/broadband-offerings-page.vue';
import BroadbandLandingPage from '../shared/components/broadband-landing-page/broadband-landing-page.vue';
import AddressSearch from '../shared/components/address-search/address-search.vue';

//Todo: align with cms project
import SubscriptionOfferingCard from '../shared/components/product/subscription-offering-card.vue';
import PowerStepAccessoryCard from 'webshop/components/power-step/power-step-accessory-card.vue';
import Countdown from 'webshop/components/product/private/countdown/countdown.vue';
import NumberWrapper from 'webshop/components/number-configuration/number-wrapper.vue';
import NumberDiva from 'webshop/components/number-configuration/number-diva.vue';
import BasketPage from 'webshop/components/shopping-cart/basket-page.vue';
import ResursBanner from 'webshop/components/shopping-cart/resurs-banner.vue';
import PriceSummary from 'webshop/components/shopping-cart/price-summary.vue';
import ShoppingCartOverview from 'webshop/components/shopping-cart/shopping-cart-overview.vue';
import ShoppingCartBadge from 'webshop/components/shopping-cart/shopping-cart-badge.vue';
import MiniShoppingCart from 'webshop/components/shopping-cart/basket-mini/mini-shopping-cart.vue';
import CreditCheck from 'webshop/components/credit-check/credit-check.vue';
import CreditCheckOverlay from 'webshop/components//credit-check/credit-check-overlay.vue';
import UpfrontPayment from 'webshop/components/credit-check/upfront-payment.vue';
import DatePicker from 'webshop/components/date-picker.vue';
import CChip from 'webshop/components/c-chip/c-chip.vue';
import CheckoutUpsale from 'webshop/components/checkout/checkout-upsale.vue';
import PowerStepInsuranceCard from 'webshop/components/power-step/power-step-insurance-card.vue';
import LimitedMenuHeader from '../shared/components/limited-menu-header/limited-menu-header.vue';
import * as VasModules from '../shared/components/vas/index.js';

import { GlobalObject } from 'webshop/_objects.js';
import SearchPanel from '../shared/components/c-search-panel/c-search-panel.vue';
import SearchResults from '../shared/components/c-search-results/c-search-results.vue';
import {
  CProductRibbon,
  CProductCard,
  CReplaceRetail,
  CCard
} from 'webshop/components';
import BusinessOrders from '../shared/components/business-orders/business-orders.vue';

import * as CheckoutModules from '../shared/components/checkout/index.js';
import ProductNotify from '../shared/components/product/product-notify.vue';
import CIcon from '../shared/components/c-icon/c-icon.vue';

//	brand-level
import storeObj from 'webshop/store/store.js';
import { ACTIONS as BASKET_ACTIONS } from 'webshop/store/basket';

import routerConfig from '../shared/router/index.js';

import './sass/styles.scss';
import { focusDetailsHiddenScrollTarget } from '../shared/js/focus-details-hidden-scroll-target.js';

const mediaQueries = new MediaQueries();

// configuration
Vue.use(mediaQueries);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(Validation);
Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(GlobalContainer);
Vue.use(ClickOutside);
Vue.use(Toast);
Vue.use(Tooltip);
Vue.use(PortalVue);

Vue.use(VueScrollTo, {
  offset: -170
});

Vue.component('CVideo', CVideo);
Vue.component('CReplaceRetail', CReplaceRetail);
Vue.component('CProductRibbon', CProductRibbon);
Vue.component('CProductCard', CProductCard);
Vue.component('CThemeBlock', CThemeBlock);
Vue.component('CModal', CModal);
Vue.component('CToast', CToast);
Vue.component('ProductNotify', ProductNotify);
Vue.component('CIcon', CIcon);
Vue.component('CBox', CBox);
Vue.component('CInformationBox', CInformationBox);
Vue.component('OrdersPage', OrderWrapper);
Vue.component('RedirectionPopup', RedirectionPopup);
Vue.component('SessionTimeoutOverlay', SessionTimeoutOverlay);
Vue.component('ShopFooter', ShopFooter);
Vue.component('CCard', CCard);
Vue.component('CheckoutDeliveryAddress', CheckoutDeliveryAddress);
Vue.component('BroadbandOfferingsPage', BroadbandOfferingsPage);
Vue.component('BroadbandLandingPage', BroadbandLandingPage);
Vue.component('AddressSearch', AddressSearch);

Vue.directive('toggle', VToggle);
Vue.directive('modal', VModal);
Vue.directive('trim', VTrim);

const store = new Store(storeObj);

if (document.getElementById('app')) {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',

    router: new VueRouter(routerConfig),

    components: {
      ProductPage,
      ProductCategoryOverview,
      CCompare,
      SubscriptionPage,
      SubscriptionPageSubcard,
      EpiContent,
      'cookie-disclaimer': CookieDisclaimer,
      'date-picker': DatePicker,
      CChip,
      'subscription-offering-card': SubscriptionOfferingCard,
      'power-step-accessory-card': PowerStepAccessoryCard,
      countdown: Countdown,
      'modal-dialog': ModalDialog,
      'number-wrapper': NumberWrapper,
      'number-diva': NumberDiva,
      'overlay-spinner': OverlaySpinner,
      BasketPage,
      ResursBanner,
      PriceSummary,
      'shopping-cart-overview': ShoppingCartOverview,
      'shopping-cart-badge': ShoppingCartBadge,
      'mini-shopping-cart': MiniShoppingCart,
      'checkout-upsale': CheckoutUpsale,
      'power-step-insurance-card': PowerStepInsuranceCard,
      'shouting-font': ShoutingFont,
      'channel-header': ChannelHeader,
      'credit-check': CreditCheck,
      'credit-check-overlay': CreditCheckOverlay,
      'upfront-payment': UpfrontPayment,
      'business-orders': BusinessOrders,
      ...CheckoutModules,
      'search-panel': SearchPanel,
      'search-results': SearchResults,
      CToastManager,
      TopupLandingPage,
      TopupResult,
      'quick-links': QuickLinks,
      'limited-menu-header': LimitedMenuHeader,
      ...VasModules
    },

    mixins: [themeProviderMixin, checkoutProviderMixn, toastProviderMixin],

    globals: GlobalObject,
    data() {
      return {
        mobileNavigationOpen: false,
        isTransparentHeader:
          document.body.contains(
            document.querySelector('.cm-c-hero-media--over-header')
          ) ||
          document.body.contains(
            document.querySelector('.cm-transparent-header')
          ),
        contextid: '',
        getIconPath: window.__APP__.iconsPath
      };
    },
    computed: {
      isSubNavOpen() {
        let isOpen = false;

        [].forEach.call(document.querySelectorAll('.cm-c-sub-nav'), subNav => {
          if (!subNav.classList.contains('hidden')) {
            isOpen = true;
          }
        });
        return isOpen;
      },
      isServiceMenuPresented() {
        let serviceMenuPresented = false;
        let servicenav = document.querySelectorAll('.cm-c-servicemenu-wrapper');
        if (servicenav.length > 0) {
          serviceMenuPresented = true;
        }
        return serviceMenuPresented;
      },
      isSubscriptionPage() {
        let subscriptionPage = false;
        let subscriptionCard = document.querySelectorAll(
          '.cm-c-subscription-card'
        );
        if (subscriptionCard.length > 0) {
          subscriptionPage = true;
        }
        return subscriptionPage;
      }
    },
    created() {
      window.addEventListener('beforeunload', () => {
        setTimeout(() => {
          this.SHOW_SPINNER();
        }, 300);
      });

      window.addEventListener('pageshow', event => {
        if (event.persisted) {
          //hide spinner if page was restored from the bfcache.
          setTimeout(() => {
            this.HIDE_SPINNER();
          }, 300);
        }
      });
    },
    mounted() {
      a11yMenu(this.$globals.eventBus, {
        expandedPanels: true
      });

      Array.from(document.querySelectorAll('.c-usp')).forEach(element =>
        uspRibbon(element, {
          listSelector: '.c-usp__list'
        })
      );

      Array.from(
        document.querySelectorAll('[data-section-type="slider"]')
      ).forEach(slider => new Slider(slider, { ...slider.dataset }));

      Array.from(document.querySelectorAll('[data-banner]')).forEach(item =>
        banner(item, {
          id: item.dataset.banner,
          closeButton: item.querySelector('.c-attention-banner__close'),
          hiddenClass: 'c-attention-banner--hidden'
        })
      );

      tabs.init();
      epiFormsSteps.init();
    },

    methods: {
      ...mapMutations([
        'SHOW_DIALOG',
        'SHOW_SPINNER',
        'HIDE_SPINNER',
        'RELOAD_PAGE'
      ]),
      ...mapActions({
        addSubscription: BASKET_ACTIONS.ADD_SUBSCRIPTION,
        removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET,
        // TODO: revisit map
        updateShippingMethod: 'updateShippingMethod'
      }),
      clickNextBtn() {
        this.$refs.nextbtn.click();
      }
    },
    store,
    mediaQueries
  });
}
/* Load Scripts from protoType */
protoType();
iePolyFill();
safariPolyFill();
humany();
svg4everybody();
focusDetailsHiddenScrollTarget();

document.__regen = () => {
  countDown();
  observeAnimations();
  initOneBlock(false, true);
};

document.__regen();
