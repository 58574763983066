<section class="product-details">
  <div class="product-details__heading">
    <h1 class="product-details__heading__title">
      {{ product.name }}
    </h1>
  </div>

  <product-short-description
    v-if="selectedVariant"
    :description="getDescription"
    :descriptionLinkText="product.descriptionLinkText"
  ></product-short-description>
</section>
