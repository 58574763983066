<section class="product-wizard">
  <section class="cm-o-layout cm-o-layout--main">
    <!-- @slot markup in this slot will be rendered at the top of the product wizard -->
    <slot name="header"></slot>

    <div
      class="product-wizard__container product-wizard__container__top cm-container cm-container--lg"
    >
      <!-- @slot markup in this slot should contains images or a gallery of the product -->
      <slot name="gallery-area"></slot>

      <!-- @slot markup in this slot will be rendered at the top of the attribute selector area -->
      <slot name="attribute-top-area"></slot>

      <!-- TODO DKT-3279 & DKT-3119 attribute-selectors should be looped through semi-generically -->
      <attribute-selector
        v-if="colorAttributes && colorAttributes.length"
        :title="content.colorHeading"
        :attributes="colorAttributes"
        class="product-wizard__color-selector"
        @attribute-selected="onColorSelected"
      >
        <product-color
          v-for="attribute in colorAttributes"
          :key="attribute.code"
          :slot="`button-${attribute.code}`"
          :value="attribute.hex"
          :name="attribute.name"
          :selected="attribute.name === selectedVariant.color"
        >
        </product-color>
      </attribute-selector>

      <attribute-selector
        v-if="capacityAttributes && capacityAttributes.length"
        :title="content.capacityHeading"
        :attributes="capacityAttributes"
        :selected-attribute-name="selectedVariant.capacity"
        class="product-wizard__capacity-selector"
        @attribute-selected="onCapacitySelected"
      >
        <span
          v-for="attribute in capacityAttributes"
          :key="attribute.code"
          :slot="`text-${attribute.code}`"
        >
          {{ attribute.name }} {{ attribute.dataUnit }}
        </span>
      </attribute-selector>

      <attribute-selector
        v-if="installmentAttributes && installmentAttributes.length > 1"
        :title="installmentHeading"
        :attributes="installmentAttributes"
        :selected-attribute-name="
          selectedInstallment && selectedInstallment.name
        "
        :disabled-attribute-names="disabledInstallments"
        class="product-wizard__payment-selector"
        @attribute-selected="onInstallmentSelected"
      >
        <div
          v-html="installmentSelectorDescription" 
          slot="attribute-description" 
          class="product-wizard__payment-selector__description">
        </div>
        <span
          v-for="attribute in installmentAttributes"
          :key="attribute.code"
          :slot="`text-${attribute.code}`"
        >
          <template v-if="attribute.shortName">
            {{ attribute.shortName }}
          </template>
          <template v-else>
            {{ attribute.name }}
          </template>
        </span>
      </attribute-selector>

      <attribute-selector
        v-if="content.enableBindingPeriodFilters && bindingPeriodAttributes && bindingPeriodAttributes.length"
        :title="content.bindingPeriodFilterHeading"
        :attributes="bindingPeriodAttributes"
        :selected-attribute-name="
          selectedBindingPeriod && selectedBindingPeriod.name
        "
        class="product-wizard__binding-selector"
        @attribute-selected="onBindingPeriodSelected"
      >
      </attribute-selector>

      <value-adding-service
        v-if="valueAddedServices"
        v-for="item in valueAddedServices"
        :key="item.code + '/' + selectedVariant.code"
        :item="item"
        :expand-label="content.showSubscriptionDetailsText"
        :collapse-label="content.hideSubscriptionDetailsText"
        :selected="selectedVasCodes.indexOf(item.code) !== -1"
        class="product-wizard__insurance-selector"
        @selected="onValueAddingServiceSelected"
        @deselected="onValueAddingServiceDeselected"
      >
      </value-adding-service>

      <div class="product-wizard__configuration-footer">
          <slot name="attribute-bottom-area"></slot>
      </div>
    </div>
  </section>

  <section class="cm-o-layout product-wizard__price-plans">
    <div class="cm-container cm-container--lg">
      <price-plan-selector
        v-if="showPricePlanSelector()"
        name="price-plan-selector"
        :headline="content.subscriptionHeading"
        :vas-headline="content.valueAddedServicesHeading"
        :collapse-label="content.hideDetailsText"
        :expand-label="content.showDetailsText"
        :options="subscriptions"
        :selected-option="selectedSubscription"
        :product-code="productCode"
        :enable-prolonging="enableProlonging"
        @select-option="onPricePlanSelected"
      ></price-plan-selector>
    </div>
  </section>

  <!-- @slot markup in this slot will be rendered at the footer of the product wizard -->
  <slot name="footer"></slot>
</section>
